import React, { Component } from 'react';
import styled from '@emotion/styled';
import VisibilitySensor from 'react-visibility-sensor';
import Typing from 'react-typing-animation';
import Typed from 'react-typed';

import TitleUnderlined from '../components/TitleUnderlined';
import convertHtml from '../utils/convertHtml';

const TypingTextBlock = styled.div`
  height: 100vh;
  background-color: #212121;
  max-height: 500px;
  color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  overflow: hidden;
`;

const TypingTextText = styled.div`
  width: 90%;
  font-weight: bold;
  font-size: 58px;
  text-align: center;
  line-height: 68px;
  color: #ffffff;
  font-family: Montserrat;
  font-style: normal;
  strong {
    color: #40e0d0;
    font-weight: 800;
  }
  underline {
    font-weight: 800;
    width: fit-content;
    line-height: 85px;
    background-image: linear-gradient(
      transparent calc(100% - (90px) / 3),
      #40e0d0 10px
    );
    background-repeat: no-repeat;
    background-size: 100% 100%;
    font-size: 90px;
    @media (max-width: 800px) {
      font-size: 68px;
      line-height: 78px;
      background-image: linear-gradient(
        transparent calc(100% - (68px) / 3),
        #40e0d0 10px
      );
    }
    @media (max-width: 500px) {
      font-size: 58px;
      line-height: 48px;
      background-image: linear-gradient(
        transparent calc(100% - (58px) / 3),
        #40e0d0 10px
      );
    }
  }

  @media (max-width: 800px) {
    font-size: 38px;
    line-height: 48px;
  }
  @media (max-width: 500px) {
    font-size: 28px;
    line-height: 38px;
  }
`;

const InnerText = styled.div``;

export class TypingText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };

  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <TypingTextBlock>
          <TypingTextText>
            {this.state.visible ? (
              <Typed
                strings={this.props.items.map((line, index) => {
                  return line.text.text;
                })}
                typeSpeed={this.props.typing_speed}
                backSpeed={this.props.backspace_speed}
                loop
              />
            ) : null}
          </TypingTextText>
        </TypingTextBlock>
      </VisibilitySensor>
    );
  }
}

export default TypingText;
