import React from 'react';
import Img from 'gatsby-image';
import style from './style.module.css';
import Arrow from './assets/arrow.svg';
import linkStripper from '../../utils/link-stripper';
import { Link } from 'gatsby';

const ButtonWithCircle = props => (
  <a href={props.link}>
    <div className={style.buttonContainer}>
      <div className={style.buttonText}>{props.text}</div>
      <div className={style.buttonCircle}>
        <Arrow className={style.arrowImg} />
      </div>
    </div>
  </a>
);

export default ButtonWithCircle;
